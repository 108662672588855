import { Logo } from '@/components/Logo';
import { config } from '@/config';
import { useAnalytics } from '@/hooks/useAnalytics';
import { Flex, Link, Text } from '@/ui';

export const Footer = () => {
  const { trackEvent } = useAnalytics();

  return (
    <Flex
      _hover={{ textDecor: 'none', bg: 'gray.50' }}
      align="center"
      as={Link}
      bg="white"
      borderRadius="full"
      gap="2.5"
      href={config.marketingUrl}
      mx="auto"
      px="5"
      py="2"
      target="_blank"
      onClick={() => trackEvent('experience:click powered-by')}
    >
      <Text
        fontSize="12px"
        fontWeight="bold"
        letterSpacing="0.72px"
        pos="relative"
        textTransform="uppercase"
        top="1px"
      >
        Powered by
      </Text>
      <Logo width={26} />
      <Text fontWeight="bold" pos="relative" top="-1px">
        agendahero
      </Text>
    </Flex>
  );
};
