import { Flex, type FlexProps } from '@/ui';

export const Page = (props: FlexProps) => {
  return (
    <Flex
      direction="column"
      flex="1"
      gap="10"
      justify="space-between" // forces the footer to the bottom of the page
      p="var(--experience-page-padding)"
      pt={0}
      {...props}
    />
  );
};
