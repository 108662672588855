import { Flex, type FlexProps } from '@/ui';

export const Content = (props: FlexProps) => {
  return (
    <Flex
      align={{ base: 'stretch', md: 'flex-start' }}
      direction={{ base: 'column', md: 'row' }}
      gap="var(--experience-block-spacing)"
      maxW="1200px"
      mx="auto"
      w="100%"
      {...props}
    />
  );
};
