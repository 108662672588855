import { AppLoader } from '@/components/AppLoader';
import { usePageExperienceContext } from '@/features/experiences/contexts/PageExperienceContext';
import { Background } from '@/features/styles/components/Background';
import { useStyles } from '@/features/styles/hooks/useStyles';
import { Box } from '@/ui';
import { Collection } from '../../components/Collection';
import { EmptyState } from '../../components/EmptyState';
import { Header } from '../../components/Header';
import { Content } from './components/Content';
import { Footer } from './components/Footer';
import { Page } from './components/Page';

export const SideHeaderLayout = () => {
  const { events, eventsQuery } = usePageExperienceContext();
  const { cssVariables } = useStyles();

  return (
    <Page sx={cssVariables}>
      <Content>
        <Box flexShrink={0} w={{ base: '100%', md: '530px' }}>
          <Header />
        </Box>

        <Box flexGrow="1" w={{ base: '100%', md: 'auto' }}>
          {eventsQuery?.isLoading ? (
            <AppLoader mt="10" />
          ) : events.length > 0 ? (
            <Collection />
          ) : (
            <EmptyState />
          )}
        </Box>
      </Content>

      <Footer />
      <Background />
    </Page>
  );
};
